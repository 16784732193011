.form-heading {
  font-weight: 500;
  margin-bottom: 20px;
}
.form-card {
  border-radius: 12px;
}
.error-msg {
  color: $danger;
  font-size: 12px;
}
.field-required {
  &:after {
    content: "*";
    color: $danger;
    margin-left: 4px;
  }
}
.form-label {
  font-size: 14px;
}
.form-label-bold {
  font-weight: 500;
}
.col-form-label {
  font-size: 14px;
  small {
    font-size: 12px;
  }
}
.form-control {
  // border-color: $gray-200;
  // background-color: $white;
  // box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  &:disabled,
  &[readonly] {
    background-color: #e9ecef !important;
    opacity: 1;
    pointer-events: none !important;
  }

  .ql-container .ql-editor {
    min-height: 120px;
    max-height: 120px;
  }
}
.input-group-text {
  border: none;
}
.input-group-text.remark,
.remark-input-group .input-group-text {
  background-color: $gray-100;
}

.remark-input-group textarea {
  resize: none;
}

.table {
  .form-control {
    border-color: transparent !important;
    background-color: $white !important;
    box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08) !important;
    &:disabled,
    &[readonly] {
      background-color: #e9ecef !important;
      opacity: 1;
      pointer-events: none !important;
    }
  }
}

.btn {
  padding-left: 10px;
  padding-right: 10px;
  &.rounded-4 {
    border-radius: 4px;
  }
}
.mn-w-180 {
  min-width: 180px;
}
.mn-w-100 {
  min-width: 100px;
}

.file-upload {
  $file-upload-bg-color: #ffffff;
  $file-upload-border-color: #c4c4c4;
  $file-uploaded-border-color: #08950f;
  $file-upload-shadow-color: rgba(51, 51, 51, 0.08);
  $file-upload-heading-color: rgba(17, 17, 17, 0.48);
  .file-upload-inner {
    width: 100%;
    background: $file-upload-bg-color;
    border: 2px dashed $file-upload-border-color;
    box-shadow: 0px 4px 16px $file-upload-shadow-color;
    border-radius: 4px;
    .file-upload-content {
      padding: 30px 20px 15px;
    }
    &.file-uploaded {
      border-color: $file-uploaded-border-color;
    }
  }
  .file-upload-heading {
    max-width: 155px;
    line-height: normal;
    color: $file-upload-heading-color;
  }
  .file-upload-msg {
    font-size: 0.7rem;
    border-radius: 4px;
    padding: 5px 16px;
    &.msg-error {
      color: #ff5555;
      background-color: #fff6f6;
    }
    &.msg-success {
      color: #08950f;
      background-color: #adf0b0;
    }
  }
  &.file-upload-sm{
    .file-upload-inner {
      .file-upload-content {
        padding: 15px 20px 15px;
      }
    }
    .file-upload-heading {
      max-width: 100%;
      line-height: normal;
      color: $file-upload-heading-color;
      margin-bottom: 15px !important;
    }
    .file-upload-msg {
      font-size: 0.6rem;
      border-radius: 4px;
      padding: 5px 16px;
    }
  }
}

.textarea-preview {
  white-space: pre;
  white-space: pre-wrap;
}

.input-preview,
.textarea-preview {
  min-height: 35px;
}

/* File Upload Actions */
.btn-kml-action {
  background-color: rgba($warning, 0.1);
  color: $warning;
  &-success {
    background-color: rgba($primary, 0.1);
    color: $primary;
  }
}

.btn-add-more {
  background-color: rgba($primary, 0.1);
  color: $primary;
  min-width: auto;
  display: inline-flex;
  align-items: center;
  &:hover {
    background-color: rgba($primary, 0.2);
    color: $primary;
  }
}

/* FAQ Search Control */
.form-control-search {
  font-size: 40px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding-left: 30px;
  padding-right: 70px;
  background-image: url('data:image/svg+xml,%3Csvg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M22.5 22.5L28.5 28.5" stroke="%23191919" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M15 24.5C20.2467 24.5 24.5 20.2467 24.5 15C24.5 9.75329 20.2467 5.5 15 5.5C9.75329 5.5 5.5 9.75329 5.5 15C5.5 20.2467 9.75329 24.5 15 24.5Z" stroke="%23191919" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
  background-repeat: no-repeat;
  background-position: right 24px center;
}

/*
Counter Step
*/

.number-counter {
  counter-reset: index var(--start-value, 0);
  .counter {
    width: 100%;
    display: flex;
    & > * {
      width: 100%;
    }
  }
  .counter:before {
    counter-increment: index;
    content: counters(index, ".", decimal) ".";
    margin-right: 10px;
    font-weight: 600;
  }
  .counter.col-md-12:before {
    margin-top: 8px;
  }
}
.number-counter-inner {
  counter-reset: index;
  .counter {
    width: 100%;
    display: flex;
    & > * {
      width: 100%;
    }
  }
  .counter:before {
    counter-increment: index;
    content: counters(index, ".", decimal) ".";
    margin-right: 10px;
    font-weight: 600;
  }
  .counter.col-md-12:before {
    margin-top: 8px;
  }
}

/* Inner-inner counter (1.1.1, 1.1.2, etc.) */
.number-counter-inner-inner {
  counter-reset: inner-inner-index; /* Reset the third level counter */
}

.number-counter-inner-inner .counter {
  width: 100%;
  display: flex;
}

.number-counter-inner-inner .counter > * {
  width: 100%;
}

.number-counter-inner-inner .counter:before {
  counter-increment: inner-inner-index; /* Increment third-level counter */
  content: counters(index, ".", decimal) "." counters(inner-index, ".", decimal) "." counters(inner-inner-index, ".", decimal) "."; /* Format: 1.1.1, 1.1.2 */
  margin-right: 10px;
  font-weight: 600;
}

.number-counter-inner-inner .counter.col-md-12:before {
  margin-top: 8px;
}

.ckeditor-form-control {
  overflow: hidden;
  h1.ck-placeholder {
    display: none;
  }
  p {
    margin-bottom: 0px;
  }
}
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  padding: 15px;
}
.ck.ck-editor__main > .ck-editor__editable {
  min-height: 175px;
  max-height: 175px;
}

.editor-250 .ck.ck-editor__main > .ck-editor__editable{
  min-height: 250px;
  max-height: 250px;
}

.form-control.disabled .ck.ck-editor__main > .ck-editor__editable {
  background-color: inherit;
}

.form-control {
  // border-color: transparent !important;
  // background-color: $white !important;
  // box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08) !important;
  &.disabled,
  &.readonly {
    background-color: #e9ecef !important;
    opacity: 1;
    pointer-events: none !important;
    .ck.ck-editor__main > .ck-editor__editable {
      background-color: inherit;
    }
  }
}

.ck-content .table table td,
.ck-content .table table th{
  border-width: 1px;
}

.bg-color-disabled {
  background-color: $disabled;
}

.max-width-70{
  max-width: 70px !important;
}


